import BrowserWebLogo from '@/assets/browser-web-logo.svg?react';
import ClaudeLogo from '@/assets/claude-logo.svg?react';
import DocumentLogo from '@/assets/document-logo.svg?react';
import GoogleGeminiIcon from '@/assets/google-gemini-logo.svg?react';
import Gpt4Icon from '@/assets/gpt4-logo.svg?react';
import Gpt4oIcon from '@/assets/gpt-4o-logo.svg?react';
import GptIcon from '@/assets/gpt-logo.svg?react';
import ImageGeneratorLogo from '@/assets/image-generator-logo.svg?react';
import LinkAndAskLogo from '@/assets/link-and-ask-logo.svg?react';
import SuperbotLogo from '@/assets/superbot-logo.svg?react';
import type {
  ConversationModel,
  UpcomingConversationModel,
} from '@/features/conversations';
import { appConfig } from '@/providers/config';

export const models: Record<
  ConversationModel | UpcomingConversationModel,
  {
    icon: SvgComponent;
    premium: boolean;
    hidden: boolean;
    standalone: boolean;
    upcoming?: boolean;
    fileUploadSupport?: boolean;
    isNew?: boolean;
    filesUploadSupport?: true;
    modelRedirectSupport?: boolean;
  }
> = {
  'gpt-3.5': {
    icon: GptIcon,
    premium: false,
    hidden: false,
    standalone: false,
    filesUploadSupport: true,
    modelRedirectSupport: true,
  },
  'gpt-4': {
    icon: Gpt4Icon,
    premium: true,
    hidden: false,
    standalone: false,
    filesUploadSupport: true,
    modelRedirectSupport: true,
  },
  'gpt-4o': {
    icon: Gpt4oIcon,
    premium: true,
    hidden: false,
    standalone: false,
    filesUploadSupport: true,
    modelRedirectSupport: true,
  },
  gemini: {
    icon: GoogleGeminiIcon,
    premium: true,
    hidden: false,
    standalone: false,
    filesUploadSupport: true,
  },
  claude: {
    icon: ClaudeLogo,
    premium: true,
    hidden: false,
    standalone: false,
  },
  superbot: {
    icon: SuperbotLogo,
    premium: true,
    hidden: true,
    standalone: false,
    filesUploadSupport: true,
  },
  chatbotapp: {
    icon: appConfig.logo,
    premium: true,
    hidden: true,
    standalone: false,
  },
  'image-generator': {
    icon: ImageGeneratorLogo,
    premium: true,
    hidden: false,
    standalone: true,
  },
  document: {
    icon: DocumentLogo,
    premium: true,
    hidden: false,
    standalone: true,
    fileUploadSupport: true,
  },
  'web-browsing': {
    icon: BrowserWebLogo,
    premium: true,
    hidden: false,
    standalone: true,
    upcoming: !appConfig.featureFlags.webBrowsing,
    isNew: appConfig.featureFlags.webBrowsing,
  },
  'link-and-ask': {
    icon: LinkAndAskLogo,
    premium: true,
    hidden: false,
    standalone: true,
    upcoming: true,
  },
  nova: {
    icon: appConfig.logo,
    premium: true,
    hidden: true,
    standalone: false,
  },
  aiapp: {
    icon: appConfig.logo,
    premium: true,
    hidden: true,
    standalone: false,
  },
};
