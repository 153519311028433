import { faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { type ChangeEventHandler, type ReactElement, useRef } from 'react';
import { toast } from 'react-toastify';

import { Icon } from '@/components';
import { usePremium } from '@/features/account';

import {
  documentsModel,
  maximumAllowedDocumentSize,
} from '../../../../types/message';
import styles from './AttachButton.module.scss';

export function AttachButton({
  onSelectFile,
  canAddDocumentFile,
  onRequestPaywall,
  multipleFiles,
  fileUploadDisabled,
}: {
  onSelectFile: (file: File) => void;
  canAddDocumentFile: boolean;
  onRequestPaywall: () => void;
  multipleFiles?: boolean;
  fileUploadDisabled?: boolean;
}): ReactElement {
  const { data: isPremium } = usePremium();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    if (event.target.files === null) {
      return;
    }

    if (!canAddDocumentFile) {
      toast.error('Maximum of 3 documents can be added.');
      return;
    }

    const file = event.target.files[0];

    if (file.size > maximumAllowedDocumentSize) {
      toast.error('File size should be less than 10MB.');
      return;
    }

    if (multipleFiles ?? false) {
      if (documentsModel.some((model) => model.mimeType === file.type)) {
        onSelectFile(file);
      } else {
        toast.error('Unsupported file type.');
        return;
      }
    } else {
      if (file.type === 'application/pdf') {
        onSelectFile(file);
      } else {
        toast.error('Only PDF files are supported.');
        return;
      }
    }

    // Reset file input value
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      <button
        type="button"
        className={`${styles['attach-button']} ${
          fileUploadDisabled ?? false ? styles['attach-button-disabled'] : ''
        }`}
        onClick={() => {
          isPremium ?? false
            ? fileInputRef.current?.click()
            : onRequestPaywall();
        }}
        disabled={fileUploadDisabled}
      >
        <Icon icon={faPaperclip} />
      </button>

      <VisuallyHidden>
        <input
          ref={fileInputRef}
          onChange={handleChange}
          name="document"
          type="file"
          aria-label="Upload Document"
          accept={
            multipleFiles ?? false
              ? documentsModel.map((model) => model.mimeType).join(',')
              : 'application/pdf'
          }
        />
      </VisuallyHidden>
    </div>
  );
}
