// sort-imports-ignore

import { useEffect, type ReactElement } from 'react';
import {
  Routes,
  Route,
  BrowserRouter,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import 'highlight.js/styles/github.css';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import * as Tooltip from '@radix-ui/react-tooltip';
import 'react-toastify/dist/ReactToastify.css';

import { persistor, store } from '@/providers/store';
import {
  AccountLayout,
  AuthLayout,
  PaymentLayout,
  PublicLayout,
  UserLayout,
} from '@/layouts';
import { initI18next } from '@/providers/i18n';
import { useAuth } from '@/hooks/auth';
import { RemoteConfigProvider } from '@/contexts';
import '@/providers/sentry';

// Fonts
import '@fontsource-variable/inter';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

// Styles, order is important.
import '@radix-ui/themes/styles.css';
import '@/styles/fonts.css';
import '@/styles/sizes.scss';
import '@/styles/borders.css';
import '@/styles/colors.css';
import '@/styles/durations.css';
import '@/styles/globals.css';
import '@/styles/reset.css';
import '@/styles/media.css';
import '@/styles/animations.css';
import '@/styles/zindex.css';
import '@/styles/shadows.css';
import '@/styles/opacity.css';
import '@/styles/toastify.scss';

import Login from '@/routes/login';
import Register from '@/routes/register';
import ForgotPassword from '@/routes/forgot-password';
import NotFound from '@/routes/404';
import Index from '@/routes/index';
import AccountHome from '@/routes/account';
import AccountSettings from '@/routes/account/settings';
import MyPlan from '@/routes/account/plan';
import LandingLogin from '@/routes/landing-login';
import LandingRegister from '@/routes/landing-register';
import LandingForgotPassword from '@/routes/landing-forgot-password';

import LandingPlan from '@/routes/account/landing-plan';
import LandingCheckout from '@/routes/landing-checkout';

import LoginConnections from '@/routes/account/login-connections';
import ActionHandler from '@/routes/action-handler';
import { useAppDispatch, useAppSelector, useFirebaseIdToken } from '@/hooks';
import ChoosePlan from '@/routes/account/choose-plan';
import GetStarted from '@/routes/get-started';
import { PasswordlessLoginRoute, selectAuth } from '@/features/auth';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { analytics, auth as firebaseAuth } from '@/providers/firebase';
import {
  logEvent,
  useEventStatus,
  useLogBigQueryEvent,
} from '@/features/analytics';
import { usePremium } from '@/features/account';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { gb } from '@/providers/growthbook';
import CheckoutRoute from './checkout';
import {
  AltCheckout,
  PaddleProvider,
  useCreateCustomerMutation,
  usePaddle,
} from '@/features/payment';
import { ExperimentProvider, useExperiment } from '@/features/experiment';
import { Theme } from '@radix-ui/themes';
import { appConfig } from '@/providers/config';

// TODO: Pages needs to lazy-loaded like below with a spinner fallback.
// const Login = lazy(async () => await import('@/routes/login'));

export const App = (): ReactElement => {
  return (
    <RemoteConfigProvider>
      <BrowserRouter>
        <HelmetProvider>
          <PaddleProvider>
            <ExperimentProvider>
              <Theme>
                <Tooltip.Provider>
                  <ReduxProvider store={store}>
                    <PersistGate
                      loading={null}
                      persistor={persistor}
                      onBeforeLift={async () => {
                        await initI18next();
                      }}
                    >
                      <AppRoutes />
                      <ToastContainer hideProgressBar />
                    </PersistGate>
                  </ReduxProvider>
                </Tooltip.Provider>
              </Theme>
            </ExperimentProvider>
          </PaddleProvider>
        </HelmetProvider>
      </BrowserRouter>
    </RemoteConfigProvider>
  );
};

const RoutesWithSentryInstrumentation =
  Sentry.withSentryReactRouterV6Routing(Routes);

function AppRoutes(): ReactElement {
  const { user } = useAppSelector(selectAuth);
  const [searchParams] = useSearchParams();
  const { data: isPremium } = usePremium();
  const [
    premiumSuccesfullyGrantedEventSent,
    setPremiumSuccesfullyGrantedEventSent,
  ] = useEventStatus('premiumSuccesfullyGranted');
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const [createCustomer] = useCreateCustomerMutation();
  const { userToken } = useFirebaseIdToken();
  const { paddleInstance } = usePaddle();
  const { anonymousId, setExperimentDetails } = useExperiment();

  const location = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appConfig.growthbookEnabled) {
      void gb.init({ streaming: true });
    }
  }, []);

  useEffect(() => {
    if (anonymousId === undefined) {
      return;
    }

    void gb.setAttributes({
      ...gb.getAttributes(),
      id: anonymousId,
    });

    gb.setTrackingCallback((experiment, result) => {
      setUserProperties(analytics, {
        experiment: experiment.key,
        variation: result.key,
      });
      setExperimentDetails(experiment.key, result.key);
    });
  }, [anonymousId, setExperimentDetails]);

  useEffect(() => {
    if (user === null) {
      return;
    }

    setUserId(analytics, user.uid);
    Sentry.setUser({ id: user.uid });

    if (paddleInstance !== null && user.email !== null) {
      paddleInstance.Update({
        pwCustomer: {
          email: user.email,
        },
      });
    }

    if ('clarity' in window && typeof window.clarity === 'function') {
      window.clarity('set', 'userId', user.uid);
    }
  }, [user, paddleInstance]);

  useEffect(() => {
    if (userToken === null || user === null || userToken === null) {
      return;
    }

    if (user.email !== null) {
      void createCustomer({
        email: user.email,
      });
    }
  }, [user, userToken, createCustomer]);

  useEffect(() => {
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');
    const utmCampaignId = searchParams.get('utm_campaign_id');
    const utmTerm = searchParams.get('utm_term');
    const utmAdset = searchParams.get('utm_adset');
    const utmContent = searchParams.get('utm_content');
    const utmAdname = searchParams.get('utm_adname');

    if (
      utmSource === null &&
      utmMedium === null &&
      utmCampaign === null &&
      utmId === null &&
      utmTerm === null &&
      utmContent === null
    ) {
      return;
    }

    const params = {
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_id: utmId ?? utmCampaignId,
      utm_term: utmTerm ?? utmAdset,
      utm_content: utmContent ?? utmAdname,
    };

    void gb.setAttributes({
      ...gb.getAttributes(),
      utmSource,
      utmMedium,
      utmCampaign,
      utmId,
      utmTerm,
      utmContent,
    });

    setUserProperties(analytics, params);
  }, [dispatch, searchParams]);

  useEffect(() => {
    const sendEvent = async (): Promise<void> => {
      if (isPremium === true && premiumSuccesfullyGrantedEventSent === false) {
        await setPremiumSuccesfullyGrantedEventSent(true);

        logBigQueryEvent('premium_succesfully_granted');
        logEvent('premium_succesfully_granted');
      }
    };

    void sendEvent();
  }, [
    isPremium,
    premiumSuccesfullyGrantedEventSent,
    setPremiumSuccesfullyGrantedEventSent,
    logBigQueryEvent,
  ]);

  const { syncUserState } = useAuth();

  useEffect(() => {
    const emailValidated = firebaseAuth.currentUser?.emailVerified;
    if (emailValidated === true || emailValidated === undefined) {
      return;
    }

    void firebaseAuth.currentUser?.reload().then(() => {
      if (firebaseAuth.currentUser?.emailVerified === true) {
        void firebaseAuth.currentUser.getIdToken(true).then(() => {
          syncUserState(firebaseAuth.currentUser);
        });
      }
    });
  }, [syncUserState, location?.pathname]);

  return (
    <GrowthBookProvider growthbook={gb}>
      <RoutesWithSentryInstrumentation>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/passwordless-login"
            element={<PasswordlessLoginRoute />}
          />
          <Route path="/auth/action" element={<ActionHandler />} />
        </Route>

        <Route element={<UserLayout />}>
          <Route path="/" element={<Index />} />
          <Route path="/chats/:id" element={<Index />} />
        </Route>

        <Route element={<AccountLayout />}>
          <Route path="/account" element={<AccountHome />} />
          <Route path="/account/settings" element={<AccountSettings />} />
          <Route path="/account/plan" element={<MyPlan />} />
          <Route
            path="/account/login-connections"
            element={<LoginConnections />}
          />
        </Route>

        <Route element={<PaymentLayout />}>
          <Route path="/checkout" element={<CheckoutRoute />} />
        </Route>

        {import.meta.env.VITE_APP_NAME === 'chatbotapp' ? (
          <>
            <Route element={<PublicLayout authRequired />}>
              <Route path="/choose-plan" element={<ChoosePlan />} />
              <Route path="/choose-plan/checkout" element={<AltCheckout />} />
            </Route>

            <Route element={<AuthLayout fullscreen />}>
              <Route path="/landing/login" element={<LandingLogin />} />
              <Route path="/landing/register" element={<LandingRegister />} />
              <Route
                path="/landing/forgot-password"
                element={<LandingForgotPassword />}
              />
            </Route>

            <Route element={<AuthLayout fullscreen />}>
              <Route path="/landing-gpt4o/login" element={<LandingLogin />} />
              <Route
                path="/landing-gpt4o/register"
                element={<LandingRegister />}
              />
              <Route
                path="/landing-gpt4o/forgot-password"
                element={<LandingForgotPassword />}
              />
            </Route>

            <Route element={<AuthLayout fullscreen />}>
              <Route path="/landing-image/login" element={<LandingLogin />} />
              <Route
                path="/landing-image/register"
                element={<LandingRegister />}
              />
              <Route
                path="/landing-image/forgot-password"
                element={<LandingForgotPassword />}
              />
            </Route>

            <Route element={<AuthLayout fullscreen />}>
              <Route
                path="/landing-aiwriting/login"
                element={<LandingLogin />}
              />
              <Route
                path="/landing-aiwriting/register"
                element={<LandingRegister />}
              />
              <Route
                path="/landing-aiwriting/forgot-password"
                element={<LandingForgotPassword />}
              />
            </Route>

            <Route element={<AuthLayout fullscreen />}>
              <Route path="/landing-pdf/login" element={<LandingLogin />} />
              <Route
                path="/landing-pdf/register"
                element={<LandingRegister />}
              />
              <Route
                path="/landing-pdf/forgot-password"
                element={<LandingForgotPassword />}
              />
            </Route>

            <Route element={<AuthLayout fullscreen />}>
              <Route path="/landing-3/login" element={<LandingLogin />} />
              <Route path="/landing-3/register" element={<LandingRegister />} />
              <Route
                path="/landing-3/forgot-password"
                element={<LandingForgotPassword />}
              />
            </Route>

            <Route element={<AuthLayout fullscreen />}>
              <Route path="/landing-2/login" element={<LandingLogin />} />
              <Route path="/landing-2/register" element={<LandingRegister />} />
              <Route
                path="/landing-2/forgot-password"
                element={<LandingForgotPassword />}
              />
            </Route>

            <Route element={<PublicLayout authRequired fullscreen />}>
              <Route path="/landing/plan" element={<LandingPlan />} />
              <Route path="/landing/checkout" element={<LandingCheckout />} />
            </Route>

            <Route element={<PublicLayout authRequired fullscreen />}>
              <Route path="/landing-gpt4o/plan" element={<LandingPlan />} />
              <Route
                path="/landing-gpt4o/checkout"
                element={<LandingCheckout />}
              />
            </Route>
            <Route element={<PublicLayout authRequired fullscreen />}>
              <Route path="/landing-image/plan" element={<LandingPlan />} />
              <Route
                path="/landing-image/checkout"
                element={<LandingCheckout />}
              />
            </Route>
            <Route element={<PublicLayout authRequired fullscreen />}>
              <Route path="/landing-aiwriting/plan" element={<LandingPlan />} />
              <Route
                path="/landing-aiwriting/checkout"
                element={<LandingCheckout />}
              />
            </Route>
            <Route element={<PublicLayout authRequired fullscreen />}>
              <Route path="/landing-pdf/plan" element={<LandingPlan />} />
              <Route
                path="/landing-pdf/checkout"
                element={<LandingCheckout />}
              />
            </Route>
            <Route element={<PublicLayout authRequired fullscreen />}>
              <Route path="/landing-3/plan" element={<LandingPlan />} />
              <Route path="/landing-3/checkout" element={<LandingCheckout />} />
            </Route>

            <Route element={<PublicLayout authRequired fullscreen />}>
              <Route path="/landing-2/plan" element={<LandingPlan />} />
              <Route path="/landing-2/checkout" element={<LandingCheckout />} />
            </Route>
          </>
        ) : null}

        <Route element={<PublicLayout />}>
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </RoutesWithSentryInstrumentation>
    </GrowthBookProvider>
  );
}
