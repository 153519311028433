import {
  type DataSnapshot,
  type DatabaseReference,
  ref as databaseRef,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
} from 'firebase/database';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import {
  getGoogleAnalyticsClientId,
  logEvent,
  useLogBigQueryEvent,
} from '@/features/analytics';
import { selectAuth } from '@/features/auth';
import {
  type ConversationModel,
  createConversationRef,
  pushConversation,
  updateConversationHistoryId,
  updateConversationModel,
  updateConversationTitle,
  useGetConversationTitleMutation,
} from '@/features/conversations';
import { isFetchBaseQueryError } from '@/helpers/services';
import { useAppSelector } from '@/hooks';
import { database } from '@/providers/firebase';
import { retryPromise } from '@/utils/retry-promise';

import { useTypewriterAnimation } from '../contexts/typewriter-animation';
import { messagesReducer } from '../helpers/messages-reducer';
import {
  ClientError,
  ResponseError,
  RetriableClientError,
} from '../types/errors';
import {
  type ApiMessageItemType,
  type DatabaseDraftMessageItemType,
  DatabaseMessageItemSchema,
  ImageState,
  type MessageDocumentType,
  type MessageImageType,
  type MessageItemType,
  type MessageListType,
} from '../types/message';
import { getFileType } from '../utils/get-error-detail';
import { useSendMessageToAssistant } from './assistant';
import { createMessageRef, pushMessage } from './create';
import {
  useGenerateImageMutation,
  useGeneratePromptMutation,
} from './image-generator';
import { updateMessage, updateMessageImageState } from './update';

export function useMessages({
  conversationId,
  currentModel,
}: {
  conversationId?: string;
  currentModel?: ConversationModel;
}): {
  messages: MessageListType;
  sendMessage: ({
    content,
    documents,
    model,
  }: {
    content: string;
    documents?: MessageDocumentType[];
    model: ConversationModel;
  }) => Promise<void>;
  isSendingMessage: boolean;
  isSendingMessageError: boolean;
  isAnswering: boolean;
  regenerateResponse: ({
    model,
    conversationHistoryId,
    message,
  }: {
    model: ConversationModel;
    conversationHistoryId?: string;
    message?: string;
  }) => Promise<void>;
  stopGenerating: () => void;
  resetMessages: () => void;
  isGeneratingImagePrompt: boolean;
  retryImageGeneration: ({
    messageId,
    image,
    imageIndex,
  }: {
    messageId: string;
    image: MessageImageType;
    imageIndex: number;
  }) => Promise<void>;
  saveStuckImages: () => void;
} {
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);
  const { logBigQueryEvent } = useLogBigQueryEvent();

  const [messages, dispatchMessages] = useReducer(messagesReducer, []);
  const [
    sendMessageToAssistant,
    {
      isLoading: isSendingMessage,
      isError: isSendingMessageError,
      isAnswering,
      abortMessageRequest,
    },
  ] = useSendMessageToAssistant();
  const [generatePrompt, { isLoading: isGeneratingImagePrompt }] =
    useGeneratePromptMutation();
  const [generateImage] = useGenerateImageMutation();

  const [getConversationTitle] = useGetConversationTitleMutation();
  const [answering, setAnswering] = useState(isAnswering);

  const newConversationIdRef = useRef<string | null>(null);
  const { setAnimatingMessageId } = useTypewriterAnimation();
  const conversationNewRef = useRef<boolean>(false);
  const activeConversationRef = useRef<string | null>(null);
  const activeModelRef = useRef<ConversationModel | null>(null);

  const historyIdRef = useRef<string | null>(null);

  const parsingErrorDataSchema = z.object({
    errorCode: z.number(),
    messageCode: z.string().optional(),
    message_code: z.string().optional(),
    success: z.boolean(),
    time: z.string(),
  });
  useEffect(() => {
    activeConversationRef.current = conversationId ?? null;
    const newConversationId = newConversationIdRef.current;

    // To maintain the integrity of the `messages` state for new conversations
    // and avoid unnecessary Firebase Realtime Database queries, we only
    // reset messages when transitioning to another conversation, not during
    // a new conversation.
    if (conversationId !== newConversationId) {
      dispatchMessages({ type: 'reset' });
      newConversationIdRef.current = null;
    }
  }, [conversationId]);

  useEffect(() => {
    activeModelRef.current = currentModel ?? null;
  }, [currentModel]);

  useEffect(() => {
    if (conversationId === undefined) {
      return;
    }

    const messagesRef = databaseRef(
      database,
      // This is under user layout and user object guaranteed to be not null.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `${auth.user!.uid}/messages/${conversationId}`,
    );

    const handleChildSave = (childData: DataSnapshot): void => {
      if (!childData.exists() || childData.key === null) return;

      const parsedChildData = DatabaseMessageItemSchema.safeParse(
        childData.val(),
      );

      if (!parsedChildData.success) {
        // eslint-disable-next-line no-console
        console.error('Error parsing child data', parsedChildData.error);
        return;
      }

      const message: MessageItemType = {
        id: childData.key,
        ...parsedChildData.data,
      };

      dispatchMessages({ type: 'set', payload: message });
    };

    const handleChildRemove = (childData: DataSnapshot): void => {
      if (!childData.exists() || childData.key === null) return;

      dispatchMessages({ type: 'remove', payload: childData.key });
    };

    const unsubscribeOnChildAdded = onChildAdded(messagesRef, handleChildSave);
    const unsubscribeOnChildChanged = onChildChanged(
      messagesRef,
      handleChildSave,
    );
    const unsubscribeOnChildRemoved = onChildRemoved(
      messagesRef,
      handleChildRemove,
    );

    return () => {
      dispatchMessages({ type: 'reset' });

      unsubscribeOnChildAdded();
      unsubscribeOnChildChanged();
      unsubscribeOnChildRemoved();

      setAnimatingMessageId(null);
    };
  }, [conversationId, auth.user, setAnimatingMessageId]);

  const createUserMessage = ({
    conversationId,
    message,
  }: {
    conversationId: string;
    message: ApiMessageItemType;
  }): {
    messageRef: DatabaseReference;
    message: Omit<DatabaseDraftMessageItemType, 'createdAt' | 'updatedAt'>;
  } => {
    const messageRef = createMessageRef({ conversationId });

    if (messageRef === null) {
      throw new RetriableClientError('Cannot create message ref');
    }

    const messageId = messageRef.key;

    if (messageId === null) {
      throw new RetriableClientError('Key of created message ref is null');
    }

    // TODO: Needs to update chat updatedAt.
    dispatchMessages({
      type: 'set',
      payload: {
        id: messageId,
        content: message.content,
        ...(message.documents !== undefined && {
          documents: message.documents,
        }),
        role: message.role,
        createdAt: null,
        updatedAt: null,
      },
    });

    return { messageRef, message };
  };

  const convertImageHistory = (
    messageList: MessageListType,
  ): ApiMessageItemType[] => {
    return messageList.map(({ role, content, images }) => ({
      role,
      content,
      ...(images !== undefined && {
        content: [
          {
            text: content,
            type: 'text',
          },
          ...images.map(({ prompt, url }) => ({
            fileUrl: url,
            text: prompt,
            type: 'image',
          })),
        ],
      }),
    })) as ApiMessageItemType[];
  };

  const createAssistantMessage = async ({
    conversationId,
    historyId,
    model,
    message,
  }: {
    conversationId: string;
    historyId?: string;
    message: ApiMessageItemType;
    model: ConversationModel;
  }): Promise<{
    messageId: string;
    messageRef: DatabaseReference;
    message: Omit<DatabaseDraftMessageItemType, 'createdAt' | 'updatedAt'>;
  }> => {
    const answerMessageRef = createMessageRef({ conversationId });

    // TODO: Handle the null case better.
    if (answerMessageRef === null) {
      throw new RetriableClientError('Cannot create message ref');
    }

    const messageId = answerMessageRef.key;

    // TODO: Handle the null case better.
    if (messageId === null) {
      throw new RetriableClientError('Key of created message ref is null');
    }

    setAnimatingMessageId(messageId);

    dispatchMessages({
      type: 'set',
      payload: {
        id: messageId,
        content: null,
        role: 'assistant',
        createdAt: null,
        updatedAt: null,
      },
    });

    const errors = messages.filter(({ error }) => error !== undefined);

    // TODO: Handle the error case better.
    if (errors.length > 0) {
      setAnimatingMessageId(null);

      dispatchMessages({
        type: 'remove',
        payload: messageId,
      });

      throw new ClientError({
        type: 'invalid-history',
        message: 'Invalid messages.',
      });
    }

    try {
      let messageContent = '';
      let messageImages: MessageImageType[] = [];
      let isAborted = false;

      if (model === 'image-generator') {
        const messagesPayload = convertImageHistory(messages);
        const { content, images } = await generatePrompt([
          ...messagesPayload,
          message,
        ]).unwrap();

        messageContent = content;
        messageImages = images.map(({ prompt, url }) => ({
          prompt,
          url,
          state: ImageState.Loading,
        }));

        setAnimatingMessageId(null);

        if (conversationId === activeConversationRef.current) {
          dispatchMessages({
            type: 'set',
            payload: {
              id: messageId,
              role: 'assistant',
              content: messageContent,
              images: messageImages,
              updatedAt: null,
              createdAt: null,
            },
          });
        }
      } else if (
        message.documents !== undefined &&
        message.documents.length > 0 &&
        message.content === ''
      ) {
        let codeType: string;
        if (message.documents.length < 2) {
          codeType = getFileType(message.documents[0].mimeType);
        } else {
          codeType = 'multiple';
        }

        dispatchMessages({
          type: 'set',
          payload: {
            id: messageId,
            role: 'assistant',
            error: {
              status: 'show-options',
              code: `${codeType}-empty-message`,
            },
            updatedAt: null,
            createdAt: null,
          },
        });

        throw new ClientError({
          code: `${codeType}-empty-message`,
          type: 'display-options',
          message: '',
        });
      } else {
        const messagesPayload = messages.map(
          ({ role, content, documents }) => ({
            role,
            content,
            ...(documents !== undefined && { documents }),
          }),
        ) as ApiMessageItemType[];

        const request = async (): Promise<void> => {
          await sendMessageToAssistant({
            model,
            messages: [
              // messagesPayload needs to be filtered out and doesn't contain any error object.
              ...messagesPayload,
              message,
            ],
            onUpdate(chunk) {
              messageContent += chunk;

              dispatchMessages({
                type: 'add-chunk',
                payload: {
                  id: messageId,
                  chunk,
                },
              });
            },
            onAbort() {
              isAborted = true;
            },
            historyId,
            onHistoryIdUpdate(historyId) {
              historyIdRef.current = historyId;
            },
          });
        };

        setAnswering(true);
        await request()
          .catch((error): Promise<void> | undefined => {
            if (
              error instanceof ResponseError &&
              (error.code === 5003 || error.code === 6003)
            ) {
              return retryPromise({
                request,
              });
            }
            throw error;
          })
          .finally(() => {
            setAnswering(false);
          });

        setAnimatingMessageId(null);

        if (messageContent === '' && isAborted) {
          dispatchMessages({
            type: 'remove',
            payload: messageId,
          });
        }

        if (messageContent === '' && !isAborted) {
          dispatchMessages({
            type: 'set',
            payload: {
              id: messageId,
              role: 'assistant',
              error: {
                status: 400,
              },
              updatedAt: null,
              createdAt: null,
            },
          });

          throw new RetriableClientError('Assistant message content is empty.');
        }

        if (conversationId === activeConversationRef.current) {
          dispatchMessages({
            type: 'set',
            payload: {
              id: messageId,
              role: 'assistant',
              content: messageContent,
              updatedAt: null,
              createdAt: null,
            },
          });
        }
      }

      return {
        messageRef: answerMessageRef,
        messageId,
        message: {
          role: 'assistant',
          content: messageContent,
          images: messageImages,
        },
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error', error);
      let status = 400;
      let errorCode: number | string | undefined;
      let type: string | undefined;
      if (error instanceof ResponseError) {
        status = error.status;
        errorCode = error.code;
      } else if (error instanceof ClientError) {
        errorCode = error.code;
        status = 0;
        type = error.type;
      } else if (isFetchBaseQueryError(error)) {
        if (typeof error.status === 'number') {
          status = error.status;
        } else if (error.status === 'PARSING_ERROR') {
          status = error.originalStatus;

          const parsedError = parsingErrorDataSchema.safeParse(
            JSON.parse(error.data),
          );
          errorCode = parsedError.success
            ? parsedError.data.errorCode
            : undefined;
        }
      }

      dispatchMessages({
        type: 'set',
        payload: {
          id: messageId,
          role: 'assistant',
          error: {
            status,
            code: errorCode,
            type,
          },
          updatedAt: null,
          createdAt: null,
        },
      });

      setAnimatingMessageId(null);

      if (errorCode !== undefined && typeof errorCode === 'number') {
        throw new ResponseError({
          status,
          code: errorCode,
          message: `Error: ${status} (${errorCode})`,
          retriable: status === 429 || status >= 500 || errorCode === 1002,
        });
      } else if (
        error instanceof RetriableClientError ||
        error instanceof ClientError
      ) {
        throw error;
      } else {
        throw new ClientError({
          type: 'unknown',
          message: `Unknown error: ${status}`,
        });
      }
    }
  };

  const updateTitle = async ({
    conversationId,
    messages,
  }: {
    conversationId: string;
    messages: ApiMessageItemType[];
  }): Promise<void> => {
    const title = await getConversationTitle(messages).unwrap();

    await updateConversationTitle({
      title,
      conversationId,
    });
  };

  const sendMessage = async ({
    content,
    documents,
    model,
    conversationHistoryId,
  }: {
    content: string;
    documents?: MessageDocumentType[];
    model: ConversationModel;
    conversationHistoryId?: string;
  }): Promise<void> => {
    const message: ApiMessageItemType = { role: 'user', content, documents };

    let currentConversationId: string | null = null;
    let newConversationRef = null;

    // Create a new conversation if there is no conversation id.
    if (conversationId === undefined) {
      conversationNewRef.current = true;
      newConversationRef = createConversationRef();

      if (typeof newConversationRef?.key === 'string') {
        currentConversationId = newConversationRef.key;
      }
    } else {
      conversationNewRef.current = false;
      currentConversationId = conversationId;
    }

    if (currentConversationId !== null) {
      const userMessage = createUserMessage({
        conversationId: currentConversationId,
        message,
      });

      const assistantMessage = await createAssistantMessage({
        conversationId: currentConversationId,
        historyId: conversationHistoryId,
        message,
        model,
      });

      await Promise.all([
        pushMessage(userMessage),
        pushMessage(assistantMessage),
      ]);

      if (newConversationRef !== null) {
        await createNewConversation({
          newConversationRef,
          currentConversationId,
          model,
          assistantMessage: assistantMessage.message.content,
          message,
        });
      }

      if (
        model === 'image-generator' &&
        assistantMessage.message.images !== undefined
      ) {
        void generateImages({
          messageId: assistantMessage.messageId,
          messageImages: assistantMessage.message.images,
          conversationId: currentConversationId,
        });
      }
    }
  };

  const createNewConversation = async ({
    newConversationRef,
    currentConversationId,
    model,
    assistantMessage,
    message,
  }: {
    newConversationRef: DatabaseReference;
    currentConversationId: string;
    model: ConversationModel;
    assistantMessage?: string;
    message: ApiMessageItemType;
  }): Promise<void> => {
    await pushConversation({ conversationRef: newConversationRef });

    newConversationIdRef.current = currentConversationId;

    if (
      activeConversationRef.current === null &&
      activeModelRef.current === model
    ) {
      navigate(`/chats/${currentConversationId}?model=${model}`, {
        replace: true,
      });
    }

    await updateConversationModel({
      model,
      conversationId: currentConversationId,
    });

    if (historyIdRef.current !== null) {
      void updateConversationHistoryId({
        historyId: historyIdRef.current,
        conversationId: currentConversationId,
      });
    }

    if (assistantMessage !== undefined) {
      updateTitle({
        conversationId: currentConversationId,
        messages: [
          message,
          {
            role: 'assistant',
            content: assistantMessage,
          },
        ],
        // eslint-disable-next-line no-console
      }).catch(console.error);
    }
  };

  const regenerateResponse = async ({
    model,
    conversationHistoryId,
    message,
  }: {
    model: ConversationModel;
    conversationHistoryId?: string;
    message?: string;
  }): Promise<void> => {
    const lastMessageIndex = messages.length - 1;
    const lastAssistantResponse = messages[lastMessageIndex];
    const lastUserMessage = messages[lastMessageIndex - 1];

    if (message !== undefined) {
      lastUserMessage.content = message;
    }

    // We should not operate if it's not a assistant message.
    if (lastAssistantResponse.role !== 'assistant') {
      return;
    }

    const messagesWithoutLastResponse = messages.slice(0, lastMessageIndex);

    const errors = messagesWithoutLastResponse.filter(
      ({ error }) => error !== undefined,
    );

    if (errors.length > 0) {
      throw new ClientError({
        type: 'invalid-history',
        message: 'Invalid messages.',
      });
    }

    setAnimatingMessageId(lastAssistantResponse.id);

    const { id, role, createdAt, updatedAt } = lastAssistantResponse;

    dispatchMessages({
      type: 'set',
      payload: {
        id,
        role,
        createdAt,
        updatedAt,

        // Preparing for loading state, content will change.
        content: null,
      },
    });

    const messagesPayload = messagesWithoutLastResponse.map(
      ({ role, content, documents }) => ({
        role,
        content,
        ...(documents !== undefined && { documents }),
      }),
    ) as ApiMessageItemType[];

    logBigQueryEvent('regenerate_response', {
      modelTypeDisplayed: model,
    });

    logEvent('regenerate_response', {
      modelTypeDisplayed: model,
    });

    try {
      let messageContent = '';
      let messageImages: MessageImageType[] = [];

      if (model === 'image-generator') {
        const messagesWithImageHistory = convertImageHistory(
          messagesWithoutLastResponse,
        );

        const { content, images } = await generatePrompt(
          messagesWithImageHistory,
        ).unwrap();

        messageContent = content;
        messageImages = images.map(({ prompt, url }) => ({
          prompt,
          url,
          state: ImageState.Loading,
        }));

        setAnimatingMessageId(null);

        if (conversationId === activeConversationRef.current) {
          dispatchMessages({
            type: 'set',
            payload: {
              id: lastAssistantResponse.id,
              role: 'assistant',
              content: messageContent,
              images: messageImages,
              updatedAt: null,
              createdAt: null,
            },
          });
        }
      } else {
        const request = async (): Promise<void> => {
          await sendMessageToAssistant({
            // messagesPayload needs to be filtered out and doesn't contain any error object.
            messages: messagesPayload,
            model,
            historyId: conversationHistoryId,
            onUpdate(chunk) {
              messageContent += chunk;

              dispatchMessages({
                type: 'add-chunk',
                payload: {
                  id: lastAssistantResponse.id,
                  chunk,
                },
              });
            },
            onHistoryIdUpdate(historyId) {
              historyIdRef.current = historyId;
            },
          });
        };

        setAnswering(true);
        await request()
          .catch((error): Promise<void> | undefined => {
            if (
              error instanceof ResponseError &&
              (error.code === 5003 || error.code === 6003)
            ) {
              return retryPromise({
                request,
              });
            }
            throw error;
          })
          .finally(() => {
            setAnswering(false);
          });

        setAnimatingMessageId(null);

        if (conversationId === activeConversationRef.current) {
          dispatchMessages({
            type: 'set',
            payload: {
              id: lastAssistantResponse.id,
              role: 'assistant',
              content: messageContent,
              updatedAt: null,
              createdAt: null,
            },
          });
        }
      }

      let currentConversationId: string | undefined;
      let newConversationRef = null;

      // Create a new conversation if there is no conversation id.
      if (conversationId === undefined && message !== undefined) {
        conversationNewRef.current = true;
        newConversationRef = createConversationRef();

        if (typeof newConversationRef?.key === 'string') {
          currentConversationId = newConversationRef.key;
        } else {
          throw new ClientError({
            message: 'Invalid conversation.',
            type: 'unknown',
          });
        }

        if (newConversationRef !== null) {
          await createNewConversation({
            newConversationRef,
            currentConversationId,
            model,
            assistantMessage: messageContent,
            message: {
              role: 'user',
              content: message,
              documents: lastUserMessage.documents,
            },
          });
        }
      } else {
        currentConversationId = conversationId;
      }

      if (currentConversationId !== undefined) {
        if (
          lastAssistantResponse.error !== undefined &&
          lastUserMessage?.content !== null
        ) {
          await updateMessage({
            conversationId: currentConversationId,
            messageId: lastUserMessage.id,
            message: {
              role: 'user',
              content: lastUserMessage.content,
              images: lastUserMessage?.images,
              documents: lastUserMessage?.documents,
              createdAt: lastUserMessage.createdAt,
            },
          });
        }
        await updateMessage({
          conversationId: currentConversationId,
          messageId: lastAssistantResponse.id,
          message: {
            role,
            content: messageContent,
            images: messageImages,
            createdAt,
          },
        });

        if (model === 'image-generator' && messageImages.length > 0) {
          void generateImages({
            messageId: lastAssistantResponse.id,
            messageImages,
            conversationId: currentConversationId,
          });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      let status = 400;
      let errorCode: string | number | undefined;
      if (error instanceof ResponseError) {
        errorCode = error.code;
      }

      if (isFetchBaseQueryError(error)) {
        if (typeof error.status === 'number') {
          status = error.status;
        } else if (error.status === 'PARSING_ERROR') {
          status = error.originalStatus;

          const parsedError = parsingErrorDataSchema.safeParse(
            JSON.parse(error.data),
          );
          errorCode = parsedError.success
            ? parsedError.data.errorCode
            : undefined;
        }
      }

      if (
        conversationId === activeConversationRef.current ||
        (message !== undefined && activeConversationRef.current === null)
      ) {
        dispatchMessages({
          type: 'set',
          payload: {
            id: lastAssistantResponse.id,
            role: 'assistant',
            error: {
              status,
              code: errorCode,
            },
            updatedAt: null,
            createdAt: null,
          },
        });
      }

      setAnimatingMessageId(null);

      if (errorCode !== undefined) {
        throw new ResponseError({
          status,
          code: errorCode,
          message: `Error: ${status} (${errorCode})`,
          retriable: status === 429 || status >= 500 || errorCode === 1002,
        });
      } else if (
        error instanceof RetriableClientError ||
        error instanceof ClientError
      ) {
        throw error;
      } else {
        throw new ClientError({
          type: 'unknown',
          message: `Unknown error: ${status}`,
        });
      }
    }
  };

  const generateImages = async ({
    conversationId,
    messageId,
    messageImages,
  }: {
    conversationId: string;
    messageId: string;
    messageImages: MessageImageType[];
  }): Promise<void> => {
    const promises = messageImages.map(async (image, index) => {
      await generateImageAndUpdateMessage({
        conversationId,
        messageId,
        image,
        imageIndex: index,
      });
    });

    await Promise.all(promises);
  };

  const generateImageAndUpdateMessage = async ({
    conversationId,
    messageId,
    image,
    imageIndex,
  }: {
    conversationId: string;
    messageId: string;
    image: MessageImageType;
    imageIndex: number;
  }): Promise<void> => {
    let newImageState = ImageState.Loading;

    try {
      await generateImage(image).unwrap();
      newImageState = ImageState.Success;

      logBigQueryEvent('api_responded', {
        source: 'success',
        modelTypeDisplayed: 'image-generator',
      });

      logEvent('api_responded', {
        source: 'success',
        modelTypeDisplayed: 'image-generator',
      });
    } catch (error) {
      newImageState = ImageState.Error;

      const ErrorSchema = z.object({
        data: z.object({
          errorCode: z.number(),
        }),
      });

      const parsedError = ErrorSchema.safeParse(error);

      let errorCode: number | null = null;

      if (parsedError.success) {
        errorCode = parsedError.data.data.errorCode;
      } else if (
        isFetchBaseQueryError(error) &&
        typeof error.status === 'number'
      ) {
        errorCode = error.status;
      }

      logBigQueryEvent('api_responded', {
        source: 'fail',
        modelTypeDisplayed: 'image-generator',
        ...(errorCode !== null && { errorCode }),
      });

      logEvent('api_responded', {
        source: 'fail',
        ...(errorCode !== null && { error_code: errorCode }),
        modelTypeDisplayed: 'image-generator',
        userId: getGoogleAnalyticsClientId(),
      });
    } finally {
      await updateMessageImageState({
        conversationId,
        messageId,
        imageIndex,
        state: newImageState,
      });
    }
  };

  const retryImageGeneration = async ({
    messageId,
    image,
    imageIndex,
  }: {
    messageId: string;
    image: MessageImageType;
    imageIndex: number;
  }): Promise<void> => {
    if (conversationId === undefined) {
      return;
    }

    await updateMessageImageState({
      conversationId,
      messageId,
      imageIndex,
      state: ImageState.Loading,
    });

    await generateImageAndUpdateMessage({
      conversationId,
      messageId,
      image,
      imageIndex,
    });
  };

  const stopGenerating = (): void => {
    // TODO: abort image generation
    abortMessageRequest();
  };

  const resetMessages = (): void => {
    dispatchMessages({
      type: 'reset',
    });
  };

  const saveStuckImages = (): void => {
    if (conversationId === undefined || conversationNewRef.current) {
      return;
    }

    messages.forEach((message) => {
      if (message.images === undefined) {
        return;
      }

      const messageId = message.id;
      const imagesStuckInLoadingState = message.images?.filter(
        ({ state }) => state === ImageState.Loading,
      );

      if (
        imagesStuckInLoadingState !== undefined &&
        imagesStuckInLoadingState.length > 0
      ) {
        imagesStuckInLoadingState.forEach((image, index) => {
          void updateMessageImageState({
            conversationId,
            messageId,
            imageIndex: index,
            state: ImageState.Error,
          });
        });
      }
    });
  };

  return {
    messages,
    sendMessage,
    isSendingMessage,
    isSendingMessageError,
    isAnswering: answering,
    regenerateResponse,
    stopGenerating,
    resetMessages,
    isGeneratingImagePrompt,
    retryImageGeneration,
    saveStuckImages,
  };
}
