import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import {
  faExclamationTriangle,
  faFile,
  faTimes,
  faWaveformLines,
} from '@fortawesome/pro-solid-svg-icons';
import cn from 'clsx';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { type ReactElement, useEffect, useState } from 'react';

import { Icon } from '@/components';

import { DocumentType, type MessageDocumentType } from '../../../types/message';
import styles from './DocumentItem.module.scss';
import { ImageItem } from './ImageItem/ImageItem';

const separatorCharacter = '•';

export function DocumentItem({
  size,
  type,
  name,
  mimeType,
  preview,
  loading = false,
  error = false,
  url,
  onClickRemove,
}: Omit<MessageDocumentType, 'url'> & {
  loading?: boolean;
  error?: boolean;
  url?: string;
  onClickRemove?: () => void;
}): ReactElement {
  const documentNames = {
    [DocumentType.Pdf]: 'PDF',
    [DocumentType.Docx]: 'DOCX',
    [DocumentType.Mpeg]: 'MPEG',
    [DocumentType.Avi]: 'AVI',
    [DocumentType.Mpg]: 'MPG',
    [DocumentType.Wmv]: 'WMV',
    [DocumentType.Mpegps]: 'MPEGPS',
    [DocumentType.Flv]: 'FLV',
    [DocumentType.Mp3]: 'MP3',
    [DocumentType.Plain]: 'PLAIN',
    [DocumentType.Wav]: 'WAV',
    [DocumentType.Png]: 'PNG',
    [DocumentType.Jpeg]: 'JPEG',
    [DocumentType.Mov]: 'MOV',
    [DocumentType.Mp4]: 'MP4',
  };

  const sizeFormatter = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
  });

  const [photoUrl, setPhotoUrl] = useState<string>();
  const [downloadUrlLoading, setDownloadUrlLoading] = useState<boolean>(false);

  useEffect(() => {
    if (url !== undefined) {
      const storage = getStorage();
      const storageRef = ref(storage, url);

      const getPhotoUrl = async (): Promise<void> => {
        setDownloadUrlLoading(true);
        try {
          const fileUrl = await getDownloadURL(storageRef);
          setPhotoUrl(fileUrl);
        } catch (error) {
          setPhotoUrl(undefined);
        } finally {
          setDownloadUrlLoading(false);
        }
      };

      void getPhotoUrl();
    }
  }, [url]);

  const formattedSize = sizeFormatter.format(size);

  const getIcon = (): ReactElement => {
    if (loading) {
      return <Icon icon={faSpinnerThird} fixedWidth spin />;
    }
    if (error) {
      return <Icon icon={faExclamationTriangle} fixedWidth />;
    }
    if (mimeType?.includes('audio') ?? false) {
      return <Icon icon={faWaveformLines} fixedWidth />;
    }
    return <Icon icon={faFile} fixedWidth />;
  };

  if (downloadUrlLoading && type > DocumentType.Wav) {
    return (
      <div className={styles['document-item__spinner']}>
        <Icon icon={faSpinnerThird} fixedWidth spin />
      </div>
    );
  }

  if ((photoUrl !== null || preview !== undefined) && type > DocumentType.Wav) {
    return (
      <ImageItem
        mimeType={mimeType}
        loading={loading}
        error={error}
        preview={preview}
        photoUrl={photoUrl}
        onClickRemove={onClickRemove}
      />
    );
  }

  return (
    <div
      className={cn(styles['document-item'], {
        [styles['document-item__loading']]: preview === undefined,
        [styles['document-item--error']]: error,
      })}
    >
      <>
        <div className={styles['document-item__icon']}>{getIcon()}</div>
        <div
          className={cn(styles['document-info'], {
            [styles['document-info--error']]: error,
          })}
        >
          <div className={styles['document-info__name']}>{name}</div>

          {!error && (
            <div className={styles['document-info__meta']}>
              <span>{documentNames[type]}</span> {separatorCharacter}{' '}
              <span>{formattedSize}</span>
            </div>
          )}

          {error && (
            <div className={styles['document-info__meta']}>
              Failed to upload
            </div>
          )}
        </div>
      </>

      {onClickRemove !== undefined && (
        <button
          className={styles['document-item__remove-button']}
          type="button"
          onClick={onClickRemove}
        >
          <Icon icon={faTimes} fixedWidth />
        </button>
      )}
    </div>
  );
}
