import type {
  ConversationModel,
  UpcomingConversationModel,
} from '@/features/conversations';
import { appConfig } from '@/providers/config';

export const contents: Record<
  ConversationModel | UpcomingConversationModel,
  {
    title: string;
    subtitle: string;
    description: string;
    examples?: string[];
  }
> = {
  'gpt-3.5': {
    title: 'ChatGPT',
    subtitle: 'Faster for everyday tasks',
    description:
      'ChatGPT, developed by OpenAI, stands as one of the fastest models available for a wide range of everyday tasks. This AI model is equipped to answer questions and provide assistance with various tasks, including composing emails, essays, and code.',
    examples: [
      'Describe futuristic cityscape at night.',
      'Write a thrilling pirate adventure.',
      'Explain quantum computing basics.',
    ],
  },
  'gpt-4': {
    title: 'GPT-4',
    subtitle: ' Legacy model',
    description: `GPT-4, OpenAI's most advanced AI model to date, excels in tasks that require advanced reasoning, complex instruction interpretation, and enhanced creativity. While it operates slightly slower than the ChatGPT model, it delivers superior outputs for tasks demanding higher reasoning skills.`,
    examples: [
      'Compose a love poem.',
      'Craft a funny space odyssey.',
      'Detail a secret agent mission.',
    ],
  },
  'gpt-4o': {
    title: 'GPT-4o',
    subtitle: 'Best for complex tasks',
    description: `GPT-4o, OpenAI's newest flagship model, is stronger and faster than GPT-4 in quantitative questions (math and physics), creative writing, and many other challenging tasks.`,
    examples: [
      'Create creative date ideas.',
      'How to bake a chocolate cake?',
      'Summarize the benefits of meditation.',
    ],
  },
  gemini: {
    title: 'Google Gemini',
    subtitle: `Google's most capable AI`,
    description: `Gemini, Google's most advanced AI, is designed for a wide range of tasks, including handling text, images, audio, and code. It excels in reasoning and understanding multimedia content, presenting a robust alternative to GPT-4o for multimodal tasks.`,
    examples: [
      'Explain the benefits of renewable energy.',
      'Describe how blockchain technology works.',
      'Summarize the key contributions of the Renaissance.',
    ],
  },
  claude: {
    title: 'Claude',
    subtitle: `Anthropic's latest AI model`,
    description: `Anthropic's Claude 3.5 Sonnet is excellent for complex tasks like coding and handling long conversations. It's also perfect for working with long documents, ensuring accuracy when retrieving and comparing information.`,
    examples: [
      'Generate interview questions.',
      "Discuss AI's impact on industries.",
      'Create a personalized workout plan for me.',
    ],
  },
  superbot: {
    title: 'Superbot',
    subtitle: 'Fast and reliable',
    description: `Anthropic's Claude 3.5 Sonnet is excellent for complex tasks like coding and handling long conversations. It's also perfect for working with long documents, ensuring accuracy when retrieving and comparing information.`,
    examples: [
      'Generate interview questions.',
      "Discuss AI's impact on industries.",
      'Create a personalized workout plan for me.',
    ],
  },
  chatbotapp: {
    title: appConfig.name,
    subtitle: 'Fast and reliable',
    description: `${appConfig.name} is a powerful model from HubX, it is great for everday tasks. It offers swift and efficient solutions, making it perfect for various applications.`,
    examples: [
      'Describe futuristic cityscape at night.',
      'Write a thrilling pirate adventure.',
      'Explain quantum computing basics.',
    ],
  },
  nova: {
    title: 'Nova',
    subtitle: 'Fast and reliable',
    description:
      'Nova is a powerful model from HubX, it is great for everday tasks. It offers swift and efficient solutions, making it perfect for various applications.',
    examples: [
      'Describe futuristic cityscape at night.',
      'Write a thrilling pirate adventure.',
      'Explain quantum computing basics.',
    ],
  },
  aiapp: {
    title: 'AI App',
    subtitle: 'Fast and reliable',
    description:
      'AI App is a powerful model from HubX, it is great for everday tasks. It offers swift and efficient solutions, making it perfect for various applications.',
    examples: [
      'Describe futuristic cityscape at night.',
      'Write a thrilling pirate adventure.',
      'Explain quantum computing basics.',
    ],
  },
  'image-generator': {
    title: 'Image Generation',
    subtitle: 'Create images using words',
    description:
      'Unleash the magic of AI with Image Generation. Instantly transform your words into stunning, mesmerizing visuals. Embark on a creative odyssey—experience the power of imagination in every pixel.',
    examples: [
      'Generate a fantasy dragon perched on a cliff.',
      'Create a serene Japanese Zen garden scene.',
      'Visualize a bustling cyberpunk street at night.',
    ],
  },
  document: {
    title: 'Upload & Ask PDF',
    subtitle: 'Chat with any PDF ',
    description: `Search or ask about anything in a document\n\nFrom legal agreements to financial reports, ${appConfig.name} brings your documents to life. You can ask questions, get summaries, find information, and more. Simply upload the PDF documents you wish to interact with. Ask questions, extract data, and summarize documents using AI.`,
  },
  'web-browsing': {
    title: 'Web Search',
    subtitle: 'Fast and reliable',
    description:
      'Get most recent answers with web search. This tool enables your AI model to connect to the Internet. If you need to discuss topics that require a Google Search or recent information from the Internet, the Web Search feature will be beneficial. Simply begin interacting with the model by typing your query. If the model needs to collect real-time information from the Internet, it will do so based on the context.',
    examples: [
      'How did Matthew Perry die?',
      'What to watch on Netflix?',
      'Are unicorns real?',
    ],
  },
  'link-and-ask': {
    title: 'Link & Ask',
    subtitle: 'Paste a link & ask',
    description:
      'Search or ask about anything in a web page\n\nThis tool allows you to enter a URL for a web page and ask any question related to the content on that web page.',
  },
};
