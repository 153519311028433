import { type Analytics, getAnalytics } from 'firebase/analytics';
import { type FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';
import i18next from 'i18next';
import { z } from 'zod';

const {
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_MEASUREMENT_ID,
  VITE_FIREBASE_DATABASE_URL,
} = import.meta.env;

const firebaseConfig: FirebaseOptions = {
  apiKey: VITE_FIREBASE_API_KEY,
  // TODO: https://firebase.google.com/docs/auth/web/redirect-best-practices
  authDomain: VITE_FIREBASE_AUTH_DOMAIN,
  projectId: VITE_FIREBASE_PROJECT_ID,
  storageBucket: VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: VITE_FIREBASE_APP_ID,
  measurementId: VITE_FIREBASE_MEASUREMENT_ID,
  databaseURL: VITE_FIREBASE_DATABASE_URL,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const remoteConfig = getRemoteConfig(firebaseApp);
export const storage = getStorage(firebaseApp);

auth.languageCode = i18next.language;

export let analytics: Analytics;

if (typeof window !== 'undefined') {
  analytics = getAnalytics(firebaseApp);
}

export const RemoteConfigSchema = z.object({
  announcements: z.array(
    z.object({
      id: z.number(),
      message: z.string(),
      startDate: z.string().datetime(),
      endDate: z.string().datetime(),
      userSegmentation: z.enum(['all', 'free', 'pro']),
      platforms: z.array(z.enum(['web', 'ios', 'android'])),
    }),
  ),
});

export type RemoteConfigType = z.infer<typeof RemoteConfigSchema>;

remoteConfig.defaultConfig = {
  announcements: JSON.stringify([]),
} satisfies Record<keyof RemoteConfigType, string | number | boolean>;

remoteConfig.settings.minimumFetchIntervalMillis =
  import.meta.env.VITE_VERCEL_ENV === 'production'
    ? 1000 * 60 * 60 * 12 // 12 hours
    : 1;
