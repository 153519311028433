import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { type ReactElement, type ReactNode } from 'react';

import { Text } from '@/components';

import styles from './AlertDialog.module.scss';

export function AlertDialogContent({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <AlertDialogPrimitive.Portal>
      <AlertDialogPrimitive.Overlay
        className={styles['alert-dialog-overlay']}
      />

      <AlertDialogPrimitive.Content className={styles['alert-dialog']}>
        {children}
      </AlertDialogPrimitive.Content>
    </AlertDialogPrimitive.Portal>
  );
}

export const AlertDialog = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogCancel = AlertDialogPrimitive.Cancel;
export const AlertDialogAction = AlertDialogPrimitive.Action;

export function AlertDialogTitle({
  children,
}: {
  children: string;
}): ReactElement {
  return (
    <AlertDialogPrimitive.Title
      asChild
      className={styles['alert-dialog-title']}
    >
      <Text
        as="p"
        weight="semi-bold"
        size={{
          initial: '3',
        }}
      >
        {children}
      </Text>
    </AlertDialogPrimitive.Title>
  );
}

export function AlertDialogDescription({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <AlertDialogPrimitive.Description
      asChild
      className={styles['alert-dialog-description']}
    >
      <Text
        as="p"
        size={{
          initial: '2',
        }}
      >
        {children}
      </Text>
    </AlertDialogPrimitive.Description>
  );
}

export function AlertDialogActions({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return <div className={styles['alert-dialog-actions']}>{children}</div>;
}
