import {
  type DatabaseReference,
  ref as databaseRef,
  set as databaseSet,
  push as pushDatabase,
  serverTimestamp,
} from 'firebase/database';

import { database } from '@/providers/firebase';
import { store } from '@/providers/store';

import { type DatabaseDraftMessageItemType } from '../types/message';

export const createMessageRef = ({
  conversationId,
}: {
  conversationId: string;
}): DatabaseReference | null => {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return null;
  }

  const messagesRef = databaseRef(
    database,
    `${user.uid}/messages/${conversationId}`,
  );

  const newMessageRef = pushDatabase(messagesRef);

  return newMessageRef;
};

export async function pushMessage({
  messageRef,
  message,
}: {
  messageRef: DatabaseReference;
  message: Omit<DatabaseDraftMessageItemType, 'createdAt' | 'updatedAt'>;
}): Promise<void> {
  const timestamp = serverTimestamp();

  const { role, content, error } = message;

  const newMessage: Omit<
    DatabaseDraftMessageItemType,
    'createdAt' | 'updatedAt'
  > & {
    createdAt: object;
    updatedAt: object;
  } = {
    role,
    createdAt: timestamp,
    updatedAt: timestamp,
  };

  if (error !== undefined) {
    newMessage.error = error;
  } else {
    newMessage.content = content;
  }

  if (message.documents !== undefined) {
    newMessage.documents = message.documents;
  }

  if (message.images !== undefined) {
    newMessage.images = message.images;
  }

  await databaseSet(messageRef, newMessage);
}
