import { z } from 'zod';

export enum ImageState {
  Loading,
  Error,
  Success,
}

export enum DocumentType {
  Pdf,
  Docx, // Not implemented and not used yet.
  Mpeg,
  Mp3,
  Plain,
  Mpegps,
  Flv,
  Avi,
  Mpg,
  Wmv,
  Wav,
  Png,
  Jpeg,
  Mov,
  Mp4,
}

export const documentsModel = [
  { mimeType: 'application/pdf', type: DocumentType.Pdf },
  { mimeType: 'audio/mpeg', type: DocumentType.Mpeg },
  { mimeType: 'audio/mp3', type: DocumentType.Mp3 },
  { mimeType: 'text/plain', type: DocumentType.Plain },
  { mimeType: 'video/mp2t', type: DocumentType.Mpegps, preview: false },
  { mimeType: 'video/x-flv', type: DocumentType.Flv, preview: false },
  { mimeType: 'video/avi', type: DocumentType.Avi, preview: false },
  { mimeType: 'video/x-ms-wmv', type: DocumentType.Wmv, preview: false },
  { mimeType: 'video/mpeg', type: DocumentType.Mpg, preview: false },

  { mimeType: 'audio/wav', type: DocumentType.Wav },
  { mimeType: 'image/png', type: DocumentType.Png },
  { mimeType: 'image/jpeg', type: DocumentType.Jpeg },
  { mimeType: 'video/quicktime', type: DocumentType.Mov },
  { mimeType: 'video/mp4', type: DocumentType.Mp4 },
];
export const maximumAllowedDocumentSize = 10 * 1024 * 1024; // 10MB

const MessageImageSchema = z.object({
  prompt: z.string(),
  state: z.nativeEnum(ImageState),
  url: z.string(),
});

export const MessageDocumentSchema = z.object({
  url: z.string(),
  name: z.string(),
  type: z.nativeEnum(DocumentType),
  size: z.number(),
  mimeType: z.string().optional(),
  preview: z.string().optional(),
  fileUploadState: z.string().optional(),
});

// Message item types that is used by components
const BaseMessageItemSchema = z.object({
  id: z.string(),
  role: z.enum(['assistant', 'user']),
  createdAt: z.number().nullable(),
  // `updatedAt` is type of `number | null`. See the comment below.
  updatedAt: z.number().nullable().optional(),
  images: z.array(MessageImageSchema).optional(),
  documents: z.array(MessageDocumentSchema).optional(),
});

const SuccessfulMessageItemSchema = BaseMessageItemSchema.extend({
  content: z.string().nullable(),
  error: z.never().optional(),
});

const ErrorMessageItemSchema = BaseMessageItemSchema.extend({
  content: z.never().optional(),
  error: z.object({
    status: z.union([z.string(), z.number()]),
    code: z.union([z.string(), z.number()]).optional(),
    type: z.string().optional(),
  }),
});

const MessageItemSchema = SuccessfulMessageItemSchema.or(
  ErrorMessageItemSchema,
);

export type MessageItemType = z.infer<typeof MessageItemSchema>;
export type MessageListType = MessageItemType[];
export type MessageImageType = z.infer<typeof MessageImageSchema>;
export type MessageDocumentType = z.infer<typeof MessageDocumentSchema>;

export type MessageDocumentDraftType = Omit<MessageDocumentType, 'url'>;

// API types
export interface ApiMessageItemType {
  role: MessageItemType['role'];
  content: NonNullable<MessageItemType['content']>;
  documents?: MessageItemType['documents'];
}

// Firebase data types
const DatabaseBaseMessageItemSchema = BaseMessageItemSchema.omit({
  id: true,
}).extend({
  createdAt: z.number(),
  // `updatedAt` is a mandatory field but since I can't run
  // migrations on Firebase Realtime Database, it needs to be
  // optional to parse existing data.
  updatedAt: z.number().optional(),
});

const SuccessfulDatabaseMessageItemSchema =
  DatabaseBaseMessageItemSchema.extend({
    content: z.string(),
    error: z.never().optional(),
  });

const ErrorDatabaseMessageItemSchema = DatabaseBaseMessageItemSchema.extend({
  content: z.never().optional(),
  error: z.object({
    status: z.number(),
    code: z.number().optional(),
  }),
});

export const DatabaseMessageItemSchema = SuccessfulDatabaseMessageItemSchema.or(
  ErrorDatabaseMessageItemSchema,
);

export type DatabaseDraftMessageItemType = z.infer<
  typeof DatabaseMessageItemSchema
>;
